import { types } from "@roo-dev/common";
import { Event, EventTracker as ET } from "@Roo/tracking/service/EventTracker/EventTrackerService";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { TECH_USER_TYPE, VET_USER_TYPE } from "@Roo/constants/UserTypeConstants";

export enum HospitalReferralPointOfEntry {
  ADMIN = "admin",
  FAILED_SUBMISSION = "failed-submission",
  SEND_ANOTHER = "send_another",
  NAVIGATION_MENU = "navigation-menu",
}

type EventContext = {
  pointOfEntry?: HospitalReferralPointOfEntry;
  [key: string]: unknown;
};

export const useReferHospitalEvents = () => {
  const { userTypeId, vetId, techId } = useRequiredAuthorizedUser();

  const getEntityInfo = () => ({
    entityType: userTypeId === VET_USER_TYPE ? Event.Entity.VET : Event.Entity.TECH,
    entityId: vetId ?? techId ?? undefined,
  });

  const sendEvent = async (
    eventName: types.EventName,
    eventType: types.EventType,
    context?: EventContext
  ) => {
    const isVet = userTypeId === VET_USER_TYPE;
    const isTech = userTypeId === TECH_USER_TYPE;

    if (isVet || isTech) {
      await ET.send({
        eventName,
        eventType,
        ...getEntityInfo(),
        ...(context || {}),
      });
    }
  };

  // Impression Events
  const trackLandingPageImpression = async (pointOfEntry?: HospitalReferralPointOfEntry) => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_VALIDATION, Event.Type.PAGE_VIEW, {
      pointOfEntry,
    });
  };

  const trackHospitalDetailsImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_CONTACT_INFO, Event.Type.IMPRESSION);
  };

  const trackReliefNeedsImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_RELIEF_NEEDS, Event.Type.IMPRESSION);
  };

  const trackReviewSendImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_SEND_EMAIL, Event.Type.IMPRESSION);
  };

  const trackSuccessImpression = async () => {
    await sendEvent(Event.Name.PROVIDER_VIEW_HOSPITAL_REFERRAL_SUCCESS, Event.Type.IMPRESSION);
  };

  // Submit Events
  const trackValidationSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_VALIDATION, Event.Type.CLICK);
  };

  const trackContactInfoSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_CONTACT_INFO, Event.Type.CLICK);
  };

  const trackReliefNeedsSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_RELIEF_NEEDS, Event.Type.CLICK);
  };

  const trackSendEmailSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_SEND_EMAIL, Event.Type.CLICK);
  };

  const trackSendAnotherSubmit = async () => {
    await sendEvent(Event.Name.PROVIDER_CLICK_HOSPITAL_REFERRAL_SEND_ANOTHER, Event.Type.CLICK);
  };

  // Validation Result Events
  const trackValidationSuccess = async () => {
    await sendEvent(
      Event.Name.PROVIDER_HOSPITAL_REFERRAL_VALIDATION_SUCCESS,
      Event.Type.IMPRESSION
    );
  };

  const trackValidationFailure = async () => {
    await sendEvent(Event.Name.PROVIDER_HOSPITAL_REFERRAL_VALIDATION_FAIL, Event.Type.IMPRESSION);
  };

  return {
    // Impression Events
    trackLandingPageImpression,
    trackHospitalDetailsImpression,
    trackReliefNeedsImpression,
    trackReviewSendImpression,
    trackSuccessImpression,
    // Submit Events
    trackValidationSubmit,
    trackContactInfoSubmit,
    trackReliefNeedsSubmit,
    trackSendEmailSubmit,
    trackSendAnotherSubmit,
    // Validation Result Events
    trackValidationSuccess,
    trackValidationFailure,
  };
};
